import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';
import _ from 'lodash';

import {addWellnessForm} from  '../../actions'

import { useDispatch, useSelector } from "react-redux";



const NewWellnessForm = ({updateModalOpen, modalOpen}) => {
    const [title, updateTitle] = useState("")
    // const [modalOpen, updateModalOpen] = useState(false)

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    function handleSubmit (event) {
      console.log('tyring to send')
      const this_user = user["custom:trust"]
      console.log(title)
      console.log(this_user)
      const params = {
        user_name: this_user,
        user_id: this_user,
        title: title,
        colour: '#63518a',
        form_array: null
      }
      dispatch(addWellnessForm(params))
      updateTitle("")
      updateModalOpen(false)
    }
    


  return (
    <div>
      <Modal
        size='mini'
        trigger={<Button 
          primary 
          onClick={() => updateModalOpen(true)}
        >New Wellness Form</Button>}
        open={modalOpen}
        // onClose={this.handleClose}
        >
        <Modal.Header>Add New Wellness Form</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Title:
                <input
                  name="title"
                  type="text"
                  value={title}
                  onChange={(event) => updateTitle(event.target.value)}
                />
              </label>
              <Divider/>
              <Button
                primary
                onClick={()=> handleSubmit()}
                content="Save"
              />
              <Button
                secondary
                onClick={() => updateModalOpen(false)}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
      
    </div>
  )
}

export default NewWellnessForm
