import React, { useState, useEffect } from "react";
import {
  Segment,
  Accordion,
  Icon,
  Divider,
  Form,
  Button,
} from "semantic-ui-react";

import NewTrigger from "./NewTrigger";
import TriggersSection from "./TriggersSection";

import { fetchTriggers } from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const WellnessTriggers = () => {
  const dispatch = useDispatch();

  const wellnessTriggers = useSelector((state) => state.wellnessTriggers);

  const [modalOpen, updateModalOpen] = useState(false);
  const [activeIndex, update_active_index] = useState(null);

  useEffect(() => {
    dispatch(fetchTriggers());
  }, []);

  function handleClick(index) {
    console.log(index);
    if (activeIndex === index) {
      update_active_index(-1);
      return;
    }
    update_active_index(index);
  }

  function handleChange(event) {
    console.log(event);
  }

  function renderTriggers() {
    return wellnessTriggers.map((trigger, index) => {
      return (
        <TriggersSection
          trigger={trigger}
          index={index}
          handleClick={handleClick}
          activeIndex={activeIndex}
        />
      );
    });
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Segment
        style={{ textAlign: "center", background: "#efefef", maxWidth: 600 }}
        color="blue"
      >
        <NewTrigger modalOpen={modalOpen} updateModalOpen={updateModalOpen} />
      </Segment>
      {renderTriggers()}
    </div>
  );
};

export default WellnessTriggers;
//33e2aa29-8319-4593-aca3-2acaa097c81f
//"33e2aa29-8319-4593-aca3-2acaa097c81f"
