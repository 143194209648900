import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Segment,
  Accordion,
  Icon,
  Divider,
  Form,
  Button,
  Dropdown,
} from "semantic-ui-react";

import NewTrigger from "./NewTrigger";

import { update_Trigger, removeTrigger } from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const TriggersSection = ({
  trigger,
  trigger_index,
  handleClick,
  activeIndex,
}) => {
  const dispatch = useDispatch();

  const form_colours = [
    { key: "#fb8b24", text: "Orange", value: "#fb8b24" },
    { key: "#27b2f3", text: "Blue", value: "#27b2f3" },
    { key: "#ac6cee", text: "Purple", value: "#ac6cee" },
    { key: "#0c9f96", text: "Green", value: "#0c9f96" },
    { key: "#2d5855", text: "Dark Green", value: "#2d5855" },
    { key: "#f4af27", text: "Yellow", value: "#f4af27" },
    { key: "#e50035", text: "Red", value: "#e50035" },
  ];

  const wellnessTriggers = useSelector((state) => state.wellnessTriggers);
  const pages = useSelector((state) => state.pages);
  const [modalOpen, updateModalOpen] = useState(false);

  const [title, update_title] = useState(trigger.title);
  const [text, update_text] = useState(trigger.text);
  const [action, update_action] = useState(trigger.action);
  const [data, update_data] = useState(trigger.data);

  const [form_colour, update_form_colour] = useState(
    trigger.colour || form_colours[0].value
  );
  const [destination, update_destination] = useState(trigger.destination);
  const [call_to_action, update_call_to_action] = useState(
    trigger.call_to_action
  );

  function saveForm() {
    console.log("asd");
    // const this_user = user["custom:trust"]
    // console.log(title)
    // console.log(this_user)
    // id: ID
    // user_name: String
    // user_id: String @index(name: "ByUserId", queryField: "TriggersByUserId")
    // title: String
    // text: String
    // action: String
    // destination: String
    // call_to_action: String
    // colour: String
    const params = {
      id: trigger.id,
      title: title,
      text: text,
      action: "Page Link",
      destination: destination,
      colour: form_colour,
      call_to_action: call_to_action,
    };
    console.log(params);
    dispatch(update_Trigger(params));
  }
  const defaultLink = () => {
    return (
      <div>
        <h4>Default Link</h4>
        <Dropdown
          placeholder="Select Page"
          fluid
          selection
          search
          defaultValue={destination}
          selectedLabel={destination}
          options={pages.map((page) => ({
            key: page.page_id,
            value: page.page_id,
            text: page.title,
          }))}
          onChange={(e, { value }) => update_destination(value.page_id)}
        />
      </div>
    );
  };

  function returnDestination() {
    const filter_pages_to_common = _.filter(pages, { user_id: "common" });
    switch (action) {
      case "Page Link":
        return (
          <>
            {/* <div> {JSON.stringify(destination)}</div>
            <div> {pages.length}</div>
            <div> {filter_pages_to_common.length}</div> */}
            <h4>Default Link</h4>
            <p>Note: Default links can only be common pages</p>
            <Dropdown
              placeholder="Select Page"
              fluid
              selection
              search
              defaultValue={destination}
              selectedLabel={destination}
              options={filter_pages_to_common.map((page) => ({
                key: page.page_id,
                value: page.page_id,
                text: page.title,
              }))}
              onChange={(e, { value }) => update_destination(value)}
            />
          </>
        );
        break;

      default:
        break;
    }
  }
  return (
    <div>
      <button onClick={() => console.log(trigger)}>Test</button>
      <Accordion styled style={{ marginTop: 10 }}>
        <Accordion.Title
          active={activeIndex === trigger_index}
          onClick={() => handleClick(trigger_index)}
        >
          <Icon name="dropdown" />
          {trigger.title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === trigger_index}>
          <h4>Title: {trigger.title}</h4>
          <Divider />
          <Form>
            <Form.Field>
              <label>
                <h4>Update Title</h4>
                <input
                  name="title"
                  type="text"
                  value={title}
                  onChange={(event) => update_title(event.target.value)}
                />
              </label>
              <label>
                <h4>Update Text</h4>
                <input
                  name="text"
                  type="text"
                  value={text}
                  onChange={(event) => update_text(event.target.value)}
                />
              </label>
              <label>
                <h4>Call to action label</h4>
                <input
                  name="call to action"
                  type="text"
                  value={call_to_action}
                  onChange={(event) =>
                    update_call_to_action(event.target.value)
                  }
                />
              </label>
              <label>
                <h4>Update Action</h4>
                <input
                  name="action"
                  type="text"
                  value={action}
                  disabled
                  onChange={(event) => update_action(event.target.value)}
                />
              </label>
              <label>
                <h4>Destination</h4>
                {returnDestination()}
              </label>
            </Form.Field>
          </Form>
          <h4>Form Colour</h4>
          <Dropdown
            placeholder="Select Form Icon"
            fluid
            selection
            search
            defaultValue={form_colour}
            selectedLabel={form_colour}
            options={form_colours.map((colours) => ({
              key: colours.key,
              value: colours.value,
              text: colours.text,
            }))}
            onChange={(e, { value }) => update_form_colour(value)}
          />
          <Divider />
          <Button primary onClick={() => saveForm()} content="Save" />
          <Button
            negative
            floated="right"
            onClick={() => dispatch(removeTrigger(trigger))}
          >
            Delete Trigger
          </Button>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default TriggersSection;
