import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';
import _ from 'lodash';

import {addWellnessQuestion} from  '../../actions'

import { useDispatch, useSelector } from "react-redux";

import answers_json from './answers.json'

const NewWellnessForm = ({updateModalOpen, modalOpen, wellnessForm}) => {
    const [title, updateTitle] = useState("")
    const [sub_title, update_sub_title] = useState("")
    const [question, update_question] = useState("")
    const [stub, update_stub] = useState("")

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    function handleSubmit (event) {
      console.log('tyring to send')
      const this_user = user["custom:trust"]
      const answers = JSON.stringify(answers_json)
      let params = {
        user_id: this_user,
        user_name: 'Test User',
        title: title,
        form_id: wellnessForm.id,
        sub_title: sub_title,
        stub: stub,
        question: question,
        answer_array: answers
      }
      dispatch(addWellnessQuestion(params, this_user))
      updateTitle("")
      update_sub_title("")
      update_question("")
      update_stub("")
      updateModalOpen(false)
    }
    


  return (
    <div>
      <Modal
        size='mini'
        trigger={<Button 
          positive 
          onClick={() => updateModalOpen(true)}
        >New Wellness Question</Button>}
        open={modalOpen}
        // onClose={this.handleClose}
        >
        <Modal.Header>Add New Wellness Question</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Title:
                <input
                  name="title"
                  type="text"
                  value={title}
                  onChange={(event) => updateTitle(event.target.value)}
                />
              </label>
              <label>
                Subheading:
                <input
                  name="title"
                  type="text"
                  value={sub_title}
                  onChange={(event) => update_sub_title(event.target.value)}
                />
              </label>
              <label>
                Question:
                <input
                  name="stub"
                  type="text"
                  value={question}
                  onChange={(event) => update_question(event.target.value)}
                />
              </label>
              <label>
                Stub:
                <input
                  name="stub"
                  type="text"
                  value={stub}
                  onChange={(event) => update_stub(event.target.value)}
                />
              </label>
              <Divider/>
              <Button
                primary
                onClick={()=> handleSubmit()}
                content="Save"
              />
              <Button
                secondary
                onClick={() => updateModalOpen(false)}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
      
    </div>
  )
}

export default NewWellnessForm
