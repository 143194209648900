import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal, Message } from 'semantic-ui-react'


import {removeWellnessForm} from  '../../actions'


const FormDelete = ({form}) => {
  const dispatch = useDispatch();
  const [modalOpen, update_modalOpen] = useState(false)
 

  function handleDeleteForm  (event) {
    console.log('asdasd')
    console.log(form.id)
    dispatch(removeWellnessForm(form))
    // if(this.state.text === this.state.pageTitle){
    //   this.props.deleteTrust()
    //   this.setState({ modalOpen: false })
    // }else {
    //   this.setState({error: true})

    // }
  }

  function handleOpen () {

  }

  function errorMessage() {
    return (
      <div>
        <Message negative>
          <Message.Header>Unable to delete</Message.Header>
          <p>The text entered does not match that requested. Please enter the word <strong><code>{this.state.pageTitle}</code></strong> to delete the page </p>
        </Message>
      </div>
    )
  }

  return (
    <Modal
      trigger={<Button basic onClick={() => update_modalOpen(true)}>Delete Page</Button>}
      open={modalOpen}
      onClose={() => update_modalOpen(false)}
      >
      <Modal.Header>Delete Page</Modal.Header>
      <Modal.Content>
        {/* {this.state.error ? (errorMessage()):(<div></div>)} */}
        <Form>
          <Form.Field>
          <h3>Click to delete the form</h3>
          {/* <h3>Are your sure your want to delete the page<i> {this.state.pageTitle}</i>?</h3>
          <p>Deleting this page will remove all of the associated pages and sections from the database.</p>
          <p>To permanently delete the page, please type <strong><code>{this.state.pageTitle}</code></strong>, in the box below</p> */}
            {/* <label>
              Confirm:
              <input
                name="text"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>*/}
            <Button
              negative
              onClick={() => handleDeleteForm()}
              content="Delete"
            /> 
            <Button
              secondary
              onClick={() => update_modalOpen(false)}
              content="Close"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
export default FormDelete;
