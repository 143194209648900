import React, { useState, useEffect } from "react";
import { Segment, Menu, Grid, Button } from "semantic-ui-react";

import _ from "lodash";

import NewWellnessForm from "./NewWellnessForm";

import { fetchWellnessForms, fetchWellnessQuestions } from "../../actions";
import { useDispatch, useSelector } from "react-redux";

import WellnessTriggers from "./WellnessTriggers";
import WellnessFormSection from "./WellnessFormSection";

const Index = () => {
  const [modalOpen, updateModalOpen] = useState(false);
  const wellnessForms = useSelector((state) => state.wellnessForm);
  const wellnessQuestions = useSelector((state) => state.wellnessQuestion);
  const user = useSelector((state) => state.user);
  const [activeIndex, updateActiveIndex] = useState(-1);
  const [activeItem, update_activeItem] = useState("Wellness");

  const [loading, update_loading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWellnessForms(user["custom:trust"]));
    dispatch(fetchWellnessQuestions(user["custom:trust"]));
    update_loading(false);
  }, []);

  function handleClick(index) {
    if (activeIndex === index) {
      updateActiveIndex(-1);
      return;
    }
    updateActiveIndex(index);
  }

  function getData() {
    var new_array = [...wellnessForms];
    wellnessForms.map((wellnessForm, index) => {
      var section_questions = _.filter(wellnessQuestions, {
        form_id: wellnessForm.id,
      });
      if (section_questions !== undefined) {
        new_array[index].form_array = section_questions;
      }
    });
    console.log(new_array);
  }

  function renderPathways() {
    const pathway_sections = wellnessForms.map((wellnessForm, index) => {
      var section_questions = _.filter(wellnessQuestions, {
        form_id: wellnessForm.id,
      });
      if (section_questions === undefined) {
        section_questions = [{ title: "" }];
      }
      console.log(section_questions);
      return (
        <WellnessFormSection
          wellnessForm={wellnessForm}
          handleClick={handleClick}
          index={index}
          activeIndex={activeIndex}
          questions={section_questions}
        />
      );
    });
    return <div>{pathway_sections}</div>;
  }

  function renderSection() {
    switch (activeItem) {
      case "Wellness":
        return (
          <div style={{ marginTop: 20 }}>
            <Segment
              style={{
                textAlign: "center",
                background: "#efefef",
                maxWidth: 600,
              }}
              color="blue"
            >
              <NewWellnessForm
                modalOpen={modalOpen}
                updateModalOpen={updateModalOpen}
              />
            </Segment>
            <div>{renderPathways()}</div>
          </div>
        );
        break;
      case "Triggers":
        return <WellnessTriggers />;

        break;

      default:
        break;
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Wellness Tracker</h1>
      <div>
        <Menu pointing>
          <Menu.Item
            name="Wellness Forms"
            active={activeItem === "Wellness"}
            onClick={() => update_activeItem("Wellness")}
          />
          <Menu.Item
            name="Triggers"
            active={activeItem === "Triggers"}
            onClick={() => update_activeItem("Triggers")}
          />
          <Menu.Menu position="right"></Menu.Menu>
        </Menu>
      </div>
      {renderSection()}
    </div>
  );
};

export default Index;
