import React, { useState, useEffect } from "react";
import {
  Accordion,
  Divider,
  Icon,
  Dropdown,
  Button,
  Form,
  Checkbox,
} from "semantic-ui-react";
import { update_WellnessQuestion, removeWellnessQuestion } from "../../actions";

import { useDispatch, useSelector } from "react-redux";

const QuestionSection = ({
  question,
  index,
  question_active_index,
  update_question_active_index,
}) => {
  const dispatch = useDispatch();

  const [title, updateTitle] = useState(question.title);
  const [sub_title, update_sub_title] = useState(question.sub_title);
  const [question_text, update_question_text] = useState(question.question);
  const [stub, update_stub] = useState(question.stub);
  const [answer_array, update_answer_array] = useState(
    JSON.parse(question.answer_array)
  );
  const [colours, update_colours] = useState([
    { title: "Green", code: "#0c9f96" },
    { title: "Yellow", code: "#e88b00" },
    { title: "Red", code: "#e50035" },
  ]);
  const [colour_selected, update_colour_selected] = useState(colours[0].value);

  const wellnessTriggers = useSelector((state) => state.wellnessTriggers);

  function update_question() {
    const params = {
      id: question.id,
      title: title,
      sub_title: sub_title,
      question: question_text,
      stub: stub,
      answer_array: JSON.stringify(answer_array),
    };
    console.log(params);

    dispatch(update_WellnessQuestion(params));
  }

  function updateAnswerArray(event, index) {
    console.log("updating");
    const new_answer_array = [...answer_array];
    new_answer_array[index].answer = event.target.value;
    update_answer_array(new_answer_array);
  }

  function updateAnswerColour(value, index) {
    console.log("updating");
    const new_answer_array = [...answer_array];
    new_answer_array[index].colour = value;
    update_answer_array(new_answer_array);
  }

  function updateTrigger(bool, index) {
    console.log("updating");
    const new_answer_array = [...answer_array];
    new_answer_array[index].trigger = !bool;
    update_answer_array(new_answer_array);
  }
  function updateTriggerValue(value, index) {
    console.log("updating");
    console.log(value);
    const new_answer_array = [...answer_array];
    new_answer_array[index].trigger_id = value;
    update_answer_array(new_answer_array);
  }

  function renderAnswerArray() {
    return answer_array.map((answer, index) => {
      return (
        <div style={{ backgroundColor: "#e6e6e6", padding: 10 }}>
          <div style={{ backgroundColor: "white", padding: 10 }}>
            <Form>
              <Form.Field>
                <label>
                  <h4>Update Answer Stem</h4>
                  <input
                    name="title"
                    type="text"
                    value={answer_array[index].answer}
                    onChange={(event) => updateAnswerArray(event, index)}
                  />
                </label>
              </Form.Field>
            </Form>
            <h4>Trigger</h4>
            <div style={{ display: "flex" }}>
              <div style={{ width: 100, paddingTop: 8 }}>
                <Checkbox
                  toggle
                  onChange={() => updateTrigger(answer.trigger, index)}
                  checked={answer.trigger}
                />
              </div>
              <div style={{ width: "200%", paddingLeft: 10 }}>
                <Dropdown
                  disabled={!answer.trigger}
                  placeholder="Select Trigger"
                  fluid
                  selection
                  search
                  defaultValue={answer_array[index].trigger_id}
                  selectedLabel={answer_array[index].trigger_id}
                  options={wellnessTriggers.map((trigger) => ({
                    key: trigger.id,
                    value: trigger.id,
                    text: trigger.title,
                  }))}
                  onChange={(e, { value }) => updateTriggerValue(value, index)}
                />
              </div>
            </div>
            <h4>Select Colour</h4>
            <Dropdown
              placeholder="Select Colour"
              fluid
              selection
              search
              defaultValue={answer_array[index].colour}
              selectedLabel={answer_array[index].colour}
              options={colours.map((colour) => ({
                key: colour.code,
                value: colour.code,
                text: colour.title,
              }))}
              onChange={(e, { value }) => updateAnswerColour(value, index)}
            />
            {/* <div>{answer.colour}</div>
                    <div>{answer.trigger}</div>
                    <div>{answer.trigger_id}</div>
                    <div>{answer.score}</div> */}
          </div>
        </div>
      );
    });
  }

  function handleClick(index) {
    if (question_active_index === index) {
      update_question_active_index(-1);
      return;
    }
    update_question_active_index(index);
  }

  function delete_question() {
    dispatch(removeWellnessQuestion(question));
    update_question_active_index(-1);
  }

  return (
    <Accordion style={{ backgroundColor: "#f3f3f3", padding: 10 }}>
      <Accordion.Title
        active={question_active_index === index}
        //index={0}
        onClick={() => handleClick(index)}
      >
        <Icon name="dropdown" />
        {question.title}
      </Accordion.Title>
      <Accordion.Content active={question_active_index === index}>
        <h4>Title: {question.title}</h4>
        <Divider />
        <Form>
          <Form.Field>
            <label>
              <h4>Update Title</h4>
              <input
                name="title"
                type="text"
                value={title}
                onChange={(event) => updateTitle(event.target.value)}
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label>
              <h4>Update Subheading</h4>
              <input
                name="title"
                type="text"
                value={sub_title}
                onChange={(event) => update_sub_title(event.target.value)}
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label>
              <h4>Update Question</h4>
              <input
                name="title"
                type="text"
                value={question_text}
                onChange={(event) => update_question_text(event.target.value)}
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label>
              <h4>Update Stub</h4>
              <input
                name="title"
                type="text"
                value={stub}
                onChange={(event) => update_stub(event.target.value)}
              />
            </label>
          </Form.Field>
          {renderAnswerArray()}
          <Button positive onClick={() => update_question()}>
            Update Question
          </Button>
          <Button negative onClick={() => delete_question()}>
            Delete Question
          </Button>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default QuestionSection;
