import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

import { addTrigger } from "../../actions";

import { useDispatch, useSelector } from "react-redux";

const NewTrigger = ({ updateModalOpen, modalOpen }) => {
  // const [modalOpen, updateModalOpen] = useState(false)

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [title, update_title] = useState("");
  const [text, update_text] = useState("");
  const [action, update_action] = useState("");
  const [data, update_data] = useState("");

  function handleSubmit(event) {
    console.log("tyring to send");
    const this_user = user["custom:trust"];
    console.log(title);
    console.log(this_user);

    const params = {
      user_name: this_user,
      user_id: this_user,
      title: title,
      text: text,
      action: action,
    };
    console.log(params);
    dispatch(addTrigger(params));

    update_title("");
    update_text("");
    update_action("");
    update_data("");

    updateModalOpen(false);
  }

  return (
    <div>
      <Modal
        size="mini"
        trigger={
          <Button primary onClick={() => updateModalOpen(true)}>
            New Trigger
          </Button>
        }
        open={modalOpen}
        // onClose={this.handleClose}
      >
        <Modal.Header>Add A New Trigger</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Title:
                <input
                  name="title"
                  type="text"
                  value={title}
                  onChange={(event) => update_title(event.target.value)}
                />
              </label>
              <label>
                Text:
                <input
                  name="text"
                  type="text"
                  value={text}
                  onChange={(event) => update_text(event.target.value)}
                />
              </label>
              <label>
                Action:
                <input
                  name="action"
                  type="text"
                  value={action}
                  onChange={(event) => update_action(event.target.value)}
                />
              </label>
              {/* <label>
                Data:
                <input
                  name="data"
                  type="text"
                  value={data}
                  onChange={(event) => update_data(event.target.value)}
                />
              </label> */}
              <Divider />
              <Button primary onClick={() => handleSubmit()} content="Save" />
              <Button
                secondary
                onClick={() => updateModalOpen(false)}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default NewTrigger;
