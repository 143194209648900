import React, { useState, useEffect } from "react";

import {
  Accordion,
  Divider,
  Icon,
  Dropdown,
  Button,
  Form,
} from "semantic-ui-react";
import { removePathway, update_WellnessForm } from "../../actions";

import NewWellnessQuestion from "./NewWellnessQuestion";

import { useDispatch, useSelector } from "react-redux";

import FormDelete from "./FormDelete";
import QuestionSection from "./QuestionSection.js";

const WellnessFormSection = ({
  wellnessForm,
  handleClick,
  index,
  activeIndex,
  questions,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [modalOpen, updateModalOpen] = useState(false);
  const [question_active_index, update_question_active_index] = useState(null);

  const form_colours = [
    { key: "#fb8b24", text: "Orange", value: "#fb8b24" },
    { key: "#27b2f3", text: "Blue", value: "#27b2f3" },
    { key: "#ac6cee", text: "Purple", value: "#ac6cee" },
    { key: "#0c9f96", text: "Green", value: "#0c9f96" },
    { key: "#2d5855", text: "Dark Green", value: "#2d5855" },
    { key: "#f4af27", text: "Yellow", value: "#f4af27" },
    { key: "#e50035", text: "Red", value: "#e50035" },
  ];
  const form_icons = [
    { key: "mental", text: "Mental", value: "mental" },
    { key: "physical", text: "Physical", value: "physical" },
  ];

  const [form_colour, update_form_colour] = useState(
    wellnessForm.colour || form_colours[0].value
  );
  const [form_icon, update_form_icon] = useState(
    wellnessForm.icon || form_icons[0].value
  );
  const [form_title, update_form_title] = useState(wellnessForm.title);

  function handleDelete() {
    console.log("delete this thing");
    dispatch(removePathway(wellnessForm));
  }

  function saveForm() {
    const params = {
      id: wellnessForm.id,
      title: form_title,
      colour: form_colour,
      icon: form_icon,
    };
    dispatch(update_WellnessForm(params));
  }

  function handleChange(event) {
    console.log(event.target.value);
    update_form_title(event.target.value);
    // const name = event.target.name
    // this.setState({[name]: event.target.value});
  }

  function returnWellnessQuestions() {
    return questions.map((question, index) => {
      return (
        <QuestionSection
          question={question}
          index={index}
          question_active_index={question_active_index}
          update_question_active_index={update_question_active_index}
        />
      );
    });
  }

  return (
    <div>
      <button
        onClick={() => console.log(JSON.parse(questions[0].answer_array))}
      >
        Test
      </button>
      <div>
        <Accordion styled style={{ marginTop: 10 }}>
          <Accordion.Title
            active={activeIndex === index}
            //index={0}
            onClick={() => handleClick(index)}
          >
            <Icon name="dropdown" />
            {wellnessForm.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <h4>Title: {wellnessForm.title}</h4>
            <Divider />
            <Form>
              <Form.Field>
                <label>
                  <h4>Update Title</h4>
                  <input
                    name="title"
                    type="text"
                    value={form_title}
                    onChange={(event) => handleChange(event)}
                  />
                </label>
              </Form.Field>
            </Form>
            <h4>Form Icon</h4>
            <Dropdown
              placeholder="Select Form Icon"
              fluid
              selection
              search
              defaultValue={form_icon}
              selectedLabel={form_icon}
              options={form_icons.map((icon) => ({
                key: icon.key,
                value: icon.value,
                text: icon.text,
              }))}
              onChange={(e, { value }) => update_form_icon(value)}
            />
            <h4>Form Colour</h4>
            <Dropdown
              placeholder="Select Form Icon"
              fluid
              selection
              search
              defaultValue={form_colour}
              selectedLabel={form_colour}
              options={form_colours.map((colours) => ({
                key: colours.key,
                value: colours.value,
                text: colours.text,
              }))}
              onChange={(e, { value }) => update_form_colour(value)}
            />
            {user.user_access !== "basic" ? (
              <div style={{ display: "inline" }}>
                {/* <PageEdit
                // page={this.props.page}
                // updatePage={this.props.update_Page}
                // user={this.state.user}
              /> */}
                <Divider />

                <NewWellnessQuestion
                  modalOpen={modalOpen}
                  updateModalOpen={updateModalOpen}
                  wellnessForm={wellnessForm}
                />
                {returnWellnessQuestions()}
                <Divider />
                <Button primary onClick={() => saveForm()} content="Save" />
                <FormDelete
                  form={wellnessForm}
                  deleteForm={handleDelete}
                  wellnessFormTitle={wellnessForm.title}
                />
              </div>
            ) : (
              <></>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    </div>
  );
};

export default WellnessFormSection;
